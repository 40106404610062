import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import './removeMiddleName.css';
import { ActionButton, Icon, TextStyle } from '@able/react';
import { focusOnModal, getAbleSpriteSheet } from '../../../../adapters/utils';
const ableSpriteSheet = getAbleSpriteSheet();

function RemoveMiddleNameModal({
    onCancel = () => { },
    onContinue = () => { },
    isOpen = false
}) {
    const modalRef = useRef();

    useEffect(() => {
        if (modalRef && modalRef.current) focusOnModal(modalRef);
    }, []);


    const modalTitle = 'Are you sure you want to delete your middle name?';
    const readerId = 'remove-middleName-modal-heading';
    return (
        <Modal
            ref={modalRef}
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen={isOpen}
            className="modal-dialog modal-lg ppv-modal"
            onRequestClose={onCancel}
            contentLabel={modalTitle}
            aria={{ labelledby: readerId, modal: 'true', role: 'dialog' }}
            shouldCloseOnEsc={true}
        >
            <div id='remove-middleName-modal' className="modal-content skip-recharge-content update-modal-content p-4">

                <div className="modal-header-no-border ppv-header update-contact-modal-header mb-3">
                    <div className='remove-modal-icon'>
                        <Icon developmentUrl={ableSpriteSheet} icon='Warning' />
                    </div>
                </div>

                <div tabIndex="-1" className="modal-heading mb-3" id={readerId}>
                    <TextStyle element={'h2'} alias="HeadingC">
                        {modalTitle}
                    </TextStyle>
                </div>
                <TextStyle alt='Base'>
                    If your ID has a middle name, you need to include it for verification.
                </TextStyle>
                <div className="remove-modal-footer">
                    <ActionButton
                        element="button"
                        variant="MediumEmphasis"
                        onClick={onCancel}
                        label="Cancel" />
                    <ActionButton
                        element="button"
                        variant="HighEmphasisDestructive"
                        onClick={onContinue}
                        label="Delete" />
                </div>
            </div>
        </Modal>
    );
}

export default RemoveMiddleNameModal;