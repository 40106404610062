import React, { Component } from 'react';
import { ActionButton, TextStyle } from '@able/react';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { dataLayerEventActions } from '../../adapters/constants';
import defaultImgSrc from '../../assets/img/svg/Warning104.svg';

class SkipRechargeModal extends Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.skipRecharge = this.skipRecharge.bind(this);
    }

    render() {
        return (
            <div className="modal-content ppv-modal-content skip-recharge-content">
                <div className="skip-recharge-content-container">
                    <img src={defaultImgSrc} className="skip-recharge-icon" alt="" />
                    <TextStyle alias="HeadingC" element="h4">
                        Please note
                    </TextStyle>
                    <TextStyle alias="Base" className="my-3">
                        If you activate your SIM without adding a recharge, you’ll be:
                    </TextStyle>
                    <ul className="skip-recharge-ul">
                        <li>
                            <TextStyle> unable to make calls, send texts and access data </TextStyle>
                        </li>
                        <li>
                            <TextStyle> unable to receive calls after 7 days.</TextStyle>
                        </li>
                    </ul>
                </div>
                <div className="skip-recharge-btn-container">
                    <ActionButton element="button" variant="MediumEmphasis" onClick={this.closeModal} className="skip-recharge-btns add-rech-btn" label="Add Recharge" />
                    <ActionButton element="button" variant="HighEmphasis" onClick={this.skipRecharge} className="skip-recharge-btns" label="Continue" />
                </div>
            </div>
        );
    }

    closeModal() {
        this.props.toggleModal();
    }

    skipRecharge() {
        const { shoppingCartId } = this.props.appData.shoppingCart;
        const selectedProduct = this.props.customerOffers.find((product) => product.tabName === this.props.showContentValue);

        const offerDetails = {
            productOfferingId: selectedProduct.tabId,
            rechargeId: null,
            starterCredit: 0,
            shoppingCartId
        };
        this.props.actions.updateSelectedOffer({
            productOfferingId: selectedProduct.tabId,
            productOfferingName: selectedProduct.tabName,
            ...this.props.plan
        });
        addDataLayerEventInfo(dataLayerEventActions.SKIP_STEP);
        this.props.actions.updateCart(offerDetails, true);
        this.props.toggleModal();
    }
}

export default SkipRechargeModal;
