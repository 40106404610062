import './billpay.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MessageSection, TextStyle } from '@able/react';
import { PAYMENT_ERROR } from '../../../adapters/errorCode';
import lock from '../../../assets/img/svg/controls-lock.svg';
import { addCartLayer, addDataLayerCartPrice, addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { getAbleSpriteSheet } from '../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

class Paypal extends Component {
    constructor() {
        super();
        const env = window.appConfig.PPA_ENV_MODE;
        let paypalEnv;
        if (env === 'prod' || env === 'staging') {
            paypalEnv = 'production';
        } else {
            paypalEnv = 'sandbox';
        }
        this.state = {
            env: paypalEnv,
            commit: true,
            style: {
                size: 'responsive', // small | medium | large | responsive
                shape: 'rect', // pill | rect
                color: 'gold', // gold | blue | silver | black
                tagline: false,
                layout: 'horizontal'
            }
        };
    }

    componentWillMount() {
        const { getBraintreePaymentInitialised, resetHostedFields } = this.props.actions;
        resetHostedFields();
        const {
            payment: { braintree: { token } = {} }
        } = this.props.state;
        const payload = {
            token
        };
        addCartLayer();
        // addDataLayerCartPrice('paymentType', 'PayPal');
        // addDataLayerEventInfo('paypalPaymentMethod');
        getBraintreePaymentInitialised(payload);
    }

    componentDidUpdate() {
        const { errors } = this.props.state.payment;
        if (errors && (errors.errorCode === PAYMENT_ERROR)) {
            document.getElementById('server-error-description').focus();
        }
    }

    render() {
        const PayPalButton = window.paypal_sdk && window.paypal_sdk.Buttons.driver('react', { React, ReactDOM });
        return (
            <div className="Paypal-form">
                {this.renderErrors()}
                <TextStyle className="Paypal-body" alias="Base">
                    You&apos;ll be redirected to PayPal to securely complete your payment.
                </TextStyle>
                <div className="Paypal-button">
                    {PayPalButton && <PayPalButton
                        commit={this.state.commit}
                        env={this.state.env}
                        style={this.state.style}
                        createOrder={(data, actions) => this.payment(data, actions)}
                        onApprove={(data, actions) => this.onApprove(data, actions)}
                    />}
                </div>
                <TextStyle className="Paypal-footer" alias="FinePrintA">
                    <span className="footer-icon-span">
                        <img className="custom-lock paypal-footer-icon" src={lock} alt="The information you provided us will be treated in accordance with this Privacy Statement" />
                    </span>
                    <div className="footer-text">
                        The information you provided us will be treated in accordance with this{' '}
                        <a href="https://www.telstra.com.au/privacy/privacy-statement" className="footer-ref" target="_blank" rel="noopener noreferrer">
                            {' '}
                            Privacy Statement
                        </a>
                    </div>
                </TextStyle>
            </div>
        );
    }
    payment(data, actions) {
        let paymentData = '';
        paymentData = {
            flow: 'checkout',
            currency: 'AUD',
            amount: this.props.amount,
            intent: 'sale',
            displayName: 'Telstra Prepaid',
            enableShippingAddress: false
        };
        const { payment: { hostedFields: { initialised = false } = {} } = {} } = this.props.state;
        const { paypalPayment } = this.props.actions;
        if (initialised) {
            return paypalPayment(paymentData, actions).then((paymentId) => {
                return paymentId;
            });
        }
        return null;
    }
    onApprove(data) {
        let payload = null;
        const {
            uiState: { loggedIn = false }
        } = this.props.state.app.appData;
        const { app: { appData: { customerInfo = {} } } = {} } = this.props.state;
        const { customerAccountUUID = '2fa85f64-5717-4562-b3fc-2c963f66afa8', identityProfileId = 'required' } = customerInfo;
        payload = {
            customerUUID: customerAccountUUID,
            paymentAmount: this.props.amount,
        };
        if (loggedIn) {
            payload.identityProfileId = identityProfileId;
        }
        const { payment: { hostedFields: { initialised = false } = {} } = {} } = this.props.state;
        const { paypalonAuthorize } = this.props.actions;
        if (initialised) {
            addDataLayerCartPrice('paymentType', 'PayPal');
            addDataLayerEventInfo('paypalPaymentMethod');
            paypalonAuthorize(payload, data);
        }
    }
    renderErrors() {
        const { errors } = this.props.state.payment;
        let errorText = '';
        let errorHeading = '';
        if (errors && (errors.hasError || errors.errorCode === PAYMENT_ERROR)) {
            errorHeading = errors.errorHeading;
            errorText = errors.errorBody;
        }
        if (errorHeading !== '') {
            return (
                <div className="my-4" id="server-error-description">
                    <MessageSection
                        variant="Error"
                        developmentUrl={ableSpriteSheet}
                        description={errorText}
                        titleText={errorHeading} />
                </div>
            );
        }
        return null;
    }
}
export default Paypal;
