import React, { useRef, useEffect, Fragment } from 'react';
import { TextStyle, ActionButton, IconButton} from '@able/react';
import Modal from 'react-modal';
import { focusOnModal, getAbleSpriteSheet } from '../../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

const CountryZoneModal = ({zones, onCloseModal, isOpenModal}) => {
    
    const modal1 = useRef(null);

    useEffect(() => {
        modal1 && modal1.current && focusOnModal(modal1, 0);
    }, []);
      
    const renderZoneList = () => {
        return (
            <div className="zone">
                <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content"
                aria-labelledby="zoneListModalLabel"
                >
                    <div className="modal-header-no-border ppv-header update-contact-modal-header">
                        <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onCloseModal} />
                    </div>

                    <div className="px-3 pt-2">
                        <div role="list" className="inclusions">
                            {/* Render the lines starting with "Zone" */}
                            {zones.map((zone, index) => {
                                const [zoneNumber, minutes, destinations] = zone.split('|');
                                const destinationList = destinations.split(',').map(dest => dest.trim());
                                // Calculate the number of items per column
                                const itemsPerColumn = Math.ceil(destinationList.length / 3);

                                // Create three columns
                                const columns = Array.from({ length: 3 }, (_, colIndex) => {
                                    const startIndex = colIndex * itemsPerColumn;
                                    const endIndex = startIndex + itemsPerColumn;
                                    return destinationList.slice(startIndex, endIndex);
                                });
                                return (
                                    <div key={index}>
                                        <TextStyle alias="HeadingC" className={'zone-label ' + (index === 0 ? 'mt-0' : '')} id={`zoneLabel-${index}`}>
                                            {`${minutes} to ${zoneNumber.toLowerCase()} destinations`}
                                        </TextStyle>
                                        <div className="zone-list">
                                            {columns.map((column, colIndex) => (
                                                <div key={colIndex} className="zone-col">
                                                    {column.map((destination, i) => (
                                                        <TextStyle key={i} role="listitem" aria-describedby={`zoneLabel-${index}`} alias="FinePrintA">{destination}</TextStyle>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                        {index < zones.length - 1 && <hr />}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <ActionButton
                        className="close-button"
                        element="button"
                        id="closeBtn"
                        variant="MediumEmphasis"
                        label="Close"
                        onClick={onCloseModal}
                    />
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Modal
                parentSelector={() => document.querySelector('#modal-ppv')}
                shouldCloseOnOverlayClick={false}
                isOpen={isOpenModal}
                className="modal-dialog modal-lg ppv-modal"
                contentLabel="Zone List"
                aria={{ labelledby: 'enter-otc-heading', modal: 'true', role: 'dialog' }}
                onRequestClose={onCloseModal}
                shouldCloseOnEsc
                ref={modal1}
            >
                {renderZoneList()}
            </Modal>
        </Fragment>
    );
};

export default CountryZoneModal;
