import './checkbox.css';
import React, { Fragment } from 'react';
import { Error } from '../../';
import { isEmpty } from '../../../adapters/validations';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { TextStyle } from '@able/react';

export class CheckBox extends React.Component {
    /**
     *Creates an instance of Checkbox.
     * @param {*} props
     * @memberof CheckBox
     */
    constructor(props) {
        super(props);
        this.state = { showPassword: false, focused: false };
    }

    componentDidMount() {
        const { input } = this.props;
        const checkboxInput = document.getElementById(`${input.name}`);

        checkboxInput &&
            checkboxInput.addEventListener('focus', () => {
                this.setState({ focused: true });
                checkboxInput.parentElement.classList.add('checkbox-tab-focus');
            });
        checkboxInput &&
            checkboxInput.addEventListener('blur', () => {
                this.setState({ focused: false });
                checkboxInput.parentElement.classList.remove('checkbox-tab-focus');
            });
    }

    componentWillReceiveProps(nextProps) {
        const { meta: { touched = false, error, submitFailed = false } = {}, fieldType } = nextProps;
        const fieldTypeVal = fieldType === 'date';
        if (submitFailed && touched && error && !fieldTypeVal) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', error);
        }
    }

    render() {
        const { input = {}, label, submitFailed = false, meta = {}, type, className = '', children, ...custom } = this.props;
        const { value, name } = input;
        const { touched = false, error } = meta;
        let inputClassName = custom.className ? custom.className : '';
        const errorIconClassName = !isEmpty(custom.errorIconClassName) ? custom.errorIconClassName : 'icon-alert-solid-round';
        inputClassName = this.state.focused ? `${inputClassName} checkbox-tab-focus` : inputClassName;
        inputClassName = touched && error ? `${inputClassName} has-error` : inputClassName;
        // For ID page Extra Styling
        const errorComponent = <Error errorDescriptionId={name} isValidationError errorText={error} errorIconClassName={errorIconClassName} />;
        return (
            <Fragment>
                <div className={`form-element checkbox-label ${inputClassName} ${className} mbl`}>
                    <input {...input} type="checkbox" className="styled-checkbox" id={name} checked={value} aria-describedby={`${name}-description ${name}-success-description ${name}-error-description`} />
                    <TextStyle htmlFor={name} element="label" alias="FinePrintA" className={value ? 'checked' : ''}>
                        <span className="checkbox-label-txt">{label}</span>
                        {<span aria-live="polite" aria-atomic="true" className="sr-only" id={`${name}Error`} />}
                    </TextStyle>
                    {!(name === 'acceptTerm') && (submitFailed || (touched && error)) && errorComponent}
                </div>
                {name === 'acceptTerm' && (submitFailed || (touched && error)) && errorComponent}
            </Fragment>
        );
    }
}

export default CheckBox;
