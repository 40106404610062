import './autorechargeConfirm.css';
import React, { Component } from 'react';
import AUTORECHARGE_LOCALE from '../../assets/locale/autorecharge-locale';
import SpinningButton from '../SpinningButton/SpinningButton';
import store from '../../store';
import { activateSim } from '../../routes/app/actions';
import { getAbleSpriteSheet, getExpiry } from '../../adapters/utils';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { ModalContent, TextStyle } from '@able/react';

const ableSpriteSheet = getAbleSpriteSheet();
class AutoRechargeConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { activatingWithAR: null };
        this.payAndActivateWithAR = this.payAndActivateWithAR.bind(this);
        this.payAndActivateWithoutAR = this.payAndActivateWithoutAR.bind(this);
        this.activateWithAR = this.activateWithAR.bind(this);
        this.activateWithoutAR = this.activateWithoutAR.bind(this);
        this.onActivate = this.onActivate.bind(this);
        this.onSkip = this.onSkip.bind(this);
    }

    render() {
        const { actualAmount = 0, plan = '', priceBeforeDiscount = 0, isModalOpen, activationInProgress } = this.props;
        const {
            confirmModel: { title, para = '', btnText1 = '', btnText2 = '' }
        } = AUTORECHARGE_LOCALE;
        const { starterCredit = 0 } = this.props.state.app.appData.serviceNumber;
        const expiry = getExpiry(plan.usageQuota);
        const value = priceBeforeDiscount || actualAmount || starterCredit;
        return (
            <ModalContent
                className='confirm-ar-modal  modal-top-shadow'
                title={title}
                icon='Attention'
                bodyContent={
                    <TextStyle classNam="" alias="Base">
                        {para}${value} every {expiry}.
                    </TextStyle>
                }
                footerContent={
                    <div className="confirm-ar-modal__footer mt-4">
                        <SpinningButton
                            id="skipActivate"
                            stylingClass="w-100"
                            variant="MediumEmphasis"
                            buttonText={btnText2}
                            isDisabled={activationInProgress}
                            isLoading={!this.state.activatingWithAR && activationInProgress}
                            onSubmit={this.onSkip}
                        />
                        <SpinningButton
                            id="confirmActivate"
                            stylingClass="w-100"
                            variant="HighEmphasis"
                            buttonText={btnText1}
                            isDisabled={activationInProgress}
                            isLoading={this.state.activatingWithAR && activationInProgress}
                            onSubmit={this.onActivate}
                        />
                    </div>
                }
                variant='Comfortable'
                isShowing={isModalOpen}
                developmentUrl={ableSpriteSheet}
                setHideDialog={() => [this.props.closeCallback(), this.props.actions.resetAutoRecharge()]}
            />
        );
    }

    closeSheet() {
        this.props.actions.resetAutoRecharge();
        this.props.closeCallback();
    }

    onActivate() {
        if (this.props.activationInProgress) {
            return;
        }
        this.setState({ activatingWithAR: true });
        const { starterCredit = 0 } = this.props.state.app.appData.serviceNumber;
        if (starterCredit === 0) {
            this.payAndActivateWithAR();
        } else {
            this.activateWithAR();
        }
    }

    onSkip() {
        if (this.props.activationInProgress) {
            return;
        }
        this.setState({ activatingWithAR: false });
        const { starterCredit = 0 } = this.props.state.app.appData.serviceNumber;
        if (starterCredit === 0) {
            this.payAndActivateWithoutAR();
        } else {
            this.activateWithoutAR();
        }
    }

    payAndActivateWithAR() {
        // Apply the auto recharge settings and activate the SIM
        const {
            autoRechargeCardData: { brainTreeNonceData = {}, savedCardData = {} }
        } = this.props.state.payment;
        const paymentData = brainTreeNonceData || savedCardData;
        const { payAndActivate } = this.props.actions;
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'confirm and activate');
        payAndActivate(paymentData);
    }

    payAndActivateWithoutAR() {
        // Skip the auto recharge settings and activate the SIM
        const {
            autoRechargeCardData: { brainTreeNonceData = {}, savedCardData = {} }
        } = this.props.state.payment;
        const paymentData = brainTreeNonceData || savedCardData;
        paymentData.registerInVault = false;
        const { payAndActivate } = this.props.actions;
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'skip and activate');
        payAndActivate(paymentData);
    }

    activateWithAR() {
        const {
            autoRechargeCardData: { brainTreeNonceData = {}, savedCardData = {} }
        } = this.props.state.payment;
        const paymentData = brainTreeNonceData || savedCardData;
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'confirm and activate');
        store.dispatch(activateSim(paymentData));
    }

    activateWithoutAR() {
        addDataLayerEventInfo('click', 'auto recharge module', 'CTA click', 'skip and activate');
        store.dispatch(activateSim());
    }
}

export default AutoRechargeConfirmationModal;
