import './TextInput.css';
import { Error } from '../../';
import React from 'react';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { isEmpty } from '../../../adapters/validations';
import Parser from 'html-react-parser';
import { TextStyle } from '@able/react';

class TextInput extends React.Component {
    /**
     *Creates an instance of TextInput.
     * @param {*} props
     * @memberof TextInput
     */
    constructor(props) {
        super(props);
        this.state = { showPassword: false };
    }

    componentWillReceiveProps(nextProps) {
        const { meta: { touched = false, error: latestError, submitFailed = false } = {}, fieldType } = nextProps;
        const fieldTypeVal = fieldType === 'date';
        if (submitFailed && touched && latestError && !fieldTypeVal) {
            addDataLayerEventInfo('error', 'Inline', 'unavailable', latestError);
        }
    }

    /**
     * Renders the text input
     * Note: Same component is use to generate password field
     * @returns
     * @memberof TextInput
     */
    render() {
        const { input = {}, label, autocomplete, arialabel, meta = {}, inputType = 'text', customAriaLabel, fieldType, ppvInputNote, ...custom } = this.props;
        const { name, value } = input;
        const { touched = false, error, submitFailed = false } = meta;
        let inputClassName = custom.className ? custom.className : '';
        const fieldTypeVal = fieldType === 'date';
        const isPasswordInput = inputType === 'password';
        inputClassName = touched && error && !fieldTypeVal ? `${inputClassName} has-error` : inputClassName;
        let validMessage;
        if (name === 'simNumber') {
            validMessage = 'SIM serial number is valid';
        } else if (isEmpty(label)) {
            validMessage = `${arialabel} is valid`;
        } else {
            validMessage = `${label} is valid`;
        }
        const normalizeValue = (dirtyValue) => {
            return dirtyValue ? dirtyValue.toString().replace(/^\s+|\s+$|\s+(?=\s)/g, '') : dirtyValue;
        };
        const showHide = () => {
            this.setState({ showPassword: !this.state.showPassword });
        };
        const assistiveElement = custom.assistiveText && (
            <TextStyle element="p" alias="FinePrintA" id={`${name}-description`} aria-live="polite" aria-atomic="true">
                {custom.assistiveText}
            </TextStyle>
        );
        return (
            <div className="form-element">
                <label className={`text-grey-dark ${custom.commonClass}`} htmlFor={name}>
                    {label}
                </label>
                <input
                    {...input}
                    type={isPasswordInput && this.state.showPassword ? 'text' : inputType}
                    onBlur={() => input.onBlur(normalizeValue(value))}
                    {...custom}
                    className={`${inputClassName} ${custom.commonClass}`}
                    aria-label={label || arialabel}
                    autoComplete={autocomplete}
                    id={name}
                    aria-describedby={`${name}-description ${name}-success-description ${name}-error-description`}
                    aria-required="true"
                />

                {
                    <span aria-live="polite" aria-atomic="true" className="sr-only">
                        {!error && value ? validMessage : ''}
                    </span>
                }
                {
                    <span aria-live="polite" aria-atomic="true" className="sr-only">
                        {submitFailed && touched && error ? 'Invalid Value is entered' : ''}
                    </span>
                }
                {submitFailed && touched && error && <i className="td-icon icon-ui-cross " aria-hidden="true" />}
                {ppvInputNote && (
                    <TextStyle element="p" alias="FinePrintA" id={`${name}-description`} tabIndex="-1" aria-live="polite" aria-atomic="true">
                        {Parser(ppvInputNote)}
                    </TextStyle>
                )}
                {submitFailed || (touched && error && !fieldTypeVal) ? <Error errorDescriptionId={name} isValidationError errorText={error} errorIconClassName={'icon-alert-solid-round'} /> : assistiveElement}
                {error && (
                    <span aria-live="polite" aria-atomic="true" className="sr-only" id={`${name}Error`}>
                        {Parser(error)}
                    </span>
                )}
                {isPasswordInput && <i role="button" tabIndex={0} aria-pressed="false" className={`password-eye ${!this.state.showPassword ? 'show-password' : 'hide-password'}`} onClick={() => showHide()} />}
            </div>
        );
    }
}

export default TextInput;
