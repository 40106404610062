import React, { useState } from 'react';
import { generateUUID, getAbleSpriteSheet, getBrandDetails } from '../../../adapters/utils';
import SpinningButton from '../../../components/SpinningButton/SpinningButton';
import { BRANDS, dataLayerEventActions } from '../../../adapters/constants';
import { addDataLayerEventInfo } from '../../../adapters/analytics-utils';
import { InlineLink, Icon, TextStyle, Accordion, AccordionItem } from '@able/react';
import CountryZoneModal from './countryZoneList/countryZoneList';

const ableSpriteSheet = getAbleSpriteSheet();
const CardList = (props) => {
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const {
        loading: { updateCartInProgress },
        deviceData: { communityPlan = false }
    } = props;

    let price = '';
    if (!props.hidePrice) {
        price = props.plan.firstTimePrice || props.plan.price;
    }
    let usageQuota;
    if (props.plan.usageQuota) {
        usageQuota = (
            <div className="usageQuota">
                {props.plan.usageQuota.map((quota) => {
                    return (
                        <TextStyle alias="Base" key={generateUUID()} className="quota pt-2">
                            {quota}
                        </TextStyle>
                    );
                })}
            </div>
        );
    }

    let planCondition;
    if (props.plan.planCondition) {
        planCondition = <TextStyle alias="FinePrintA" colour="Subtle" className="plan-condition">{props.plan.planCondition}</TextStyle>;
    }

    const { starterCredit } = props.appData.serviceNumber;
    const selectPlan = () => {
        setIsLoadingButton(true);
        const { shoppingCartId } = props.appData.shoppingCart;

        const offerDetails = {
            productOfferingId: props.productOfferingId,
            rechargeId: props.plan.id,
            starterCredit,
            shoppingCartId
        };
        addDataLayerEventInfo(dataLayerEventActions.PROD_VIEW);
        props.actions.updateSelectedOffer({
            productOfferingId: props.productOfferingId,
            productOfferingName: props.productOfferingName,
            ...props.plan
        });
        props.actions.updateCart(offerDetails, false);
    };
    const togglePriceRiseModal = () => {
        props.togglePlanModal();
        props.setPlan(props);
    }
    const {
        deviceData: { simVersion = '' }
    } = props;
    const discount = props.plan.firstTimeDiscountPercentage || props.plan.firstTimeDiscountPrice;
    let isJBHiFi = false;
    isJBHiFi = getBrandDetails().brandName === BRANDS.JBHiFi.name;
    let priceRise = false;
    priceRise = simVersion === 'PRE_PRICE_RISE';
    return (
        <fieldset tabIndex="-1" role="listitem" className={`${(discount || props.plan.includedBonus) ? 'bonus-border' : null} col-md-5 col-xs-12 card-container recharge-card-container`}>
            {(discount || props.plan.includedBonus) &&
                <TextStyle element="legend" alias="LabelA1">
                    {props.plan.includedBonus}{props.plan.includedBonus && discount && ` - `}{discount && `${discount} OFF`}
                </TextStyle>
            }
            <div tabIndex="-1" className="recharge-card" aria-label={`${props.plan.planHeading} plan`}>
                <div className="recharge-card__top">
                    <div className="d-flex">
                        <div className="d-flex info-and-price">
                            <TextStyle role="heading" aria-level="3" className="planheading order-1" alias="HeadingC">{props.plan.planHeading}</TextStyle>
                            <div className="price-ftdiscount">
                                <TextStyle className="plan-amount" alias="HeadingC">{price}</TextStyle>
                                {(props.plan.firstTimeDiscountPrice || props.plan.firstTimeDiscountPercentage) && <TextStyle alias="FinePrintA">Was {props.plan.price}</TextStyle>}
                            </div>
                        </div>
                    </div>
                    {usageQuota}
                    {planCondition}
                    {props.plan.promotionalTemplate && <TextStyle alias="FinePrintA" colour="Subtle" className="promotional-template mt-2">{props.plan.promotionalTemplate}</TextStyle>}
                    {props.plan.firstTimeTemplate && <TextStyle alias="FinePrintA" colour="Subtle" className="promotional-template mt-2">{props.plan.firstTimeTemplate}</TextStyle>}
                    <SpinningButton
                        stylingClass="card-select w-100"
                        id={props.plan.id}
                        onSubmit={!isJBHiFi && priceRise && !communityPlan ? togglePriceRiseModal : selectPlan}
                        variant="MediumEmphasis"
                        isDisabled={updateCartInProgress}
                        isLoading={updateCartInProgress && isLoadingButton}
                        buttonText={starterCredit === 0 ? "Select" : "Continue"}
                    />
                </div>
                {props.plan.inclusions && props.plan.inclusions.length !== 0 &&
                    <Accordion>
                        <AccordionItem
                            developmentUrl={ableSpriteSheet}
                            label="What else is included?"
                            titleElement="h2"
                            children={getInclusions(props.plan.inclusions)}
                        />
                    </Accordion>
                }
            </div>
        </fieldset>
    );
};

// const getInclusions = (inclusions) => {
//     return (
//         <div role="list" className="inclusions">
//             {inclusions.map((item) => {
//                 return (
//                     <div role="listitem" key={generateUUID()} className="inclusion-container">
//                         <span className="custom-tick-latest">
//                             <TickSvg aria-hidden />
//                         </span>
//                         <span className="inclusion-item">{item}</span>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// };
const getInclusions = (inclusions) => {
    let zoneLines = [];
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    zoneLines = inclusions.filter(line => line.startsWith("Zone"));

    return (
        <div role="list" className="inclusions">
            {inclusions.map((item) => (
                !item.startsWith("Zone") ? (
                    <div role="listitem" key={generateUUID()} className="inclusion-container">
                        <span className="blue-tick-icon">
                            <Icon icon="Tick" developmentUrl={ableSpriteSheet} />
                        </span>
                        <span className="inclusion-item">{item}</span>
                    </div>
                ) : null
            ))}
            {zoneLines.length > 0 && <div>
                <InlineLink>
                    <TextStyle alias="FinePrintA" className="mt-3 mbxxl inclusion-container">
                        <span className="blue-tick-icon">
                            <Icon icon="Tick" developmentUrl={ableSpriteSheet} />
                        </span><p className="inclusion-item">International call <a id="zone-list" tabIndex="0" role="button" onClick={openModal}>minutes to selected destinations</a></p>
                    </TextStyle>
                </InlineLink>
                <CountryZoneModal zones={zoneLines} onCloseModal={closeModal} isOpenModal={isModalOpen} />
            </div>}
        </div>
    );
};

export default CardList;
