import { TextStyle } from '@able/react';
import React from 'react';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import ReadOnlyField from '../readonlyField/readOnlyField';

const { soleTrader } = CONTACT_PAGE_CONSTANTS;

export default function BusinessRegistrationReadOnly({
    legalName,
    businessName,
    abn,
    accountNumber
}) {
    return (
        <div>
            <TextStyle element="h2" alias="HeadingC" className="business-subheading mb-2">{soleTrader.title}</TextStyle>
            <TextStyle className="mb-3" alias="Base">{soleTrader.subTitle}</TextStyle>
            <div className="abn-acn-success-container">
                <ReadOnlyField label="ABN or ACN" value={abn} bottom="spacing3x" />
                <TextStyle alias="HeadingC" className="business-subheading mb-2">Business name</TextStyle>
                <ReadOnlyField label="Entity (legal) name" value={legalName} />
                <ReadOnlyField label="Business name" value={businessName} bottom={accountNumber ? 'spacing2x' : 'spacing1x'} />
                {!!accountNumber && <ReadOnlyField label="Account number" value={accountNumber} bottom="spacing1x" />}
            </div>
        </div>
    );
}
