import React from 'react';
import { TextStyle } from '@able/react';

const FieldText = ({ children, className }) => {
    return (
        <TextStyle alias="Base" className={`field-text ${className}`}>
            {children}
        </TextStyle>
    );
};

export default FieldText;
