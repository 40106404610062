const ACTIVATION_GUIDE_LOCALE = {
    title: {
        esimTitle: 'Download your eSIM profile',
        activationTitle: 'Your service is being activated'
    },
    porting: {
        esimPorting: 'Nearly there! Please download your eSIM profile to continue to activation. Your current service will work until your eSIM service is activated.',
        physicalSimPorting: 'Your mobile number is being transferred to the {{displayName}}. This can take up to 1 business day.',
        portingSimHeader: '1. Wait for your service to stop working, then insert your {{displayName}} SIM',
        portingSimContent: 'Keep your current SIM (from the provider you’re leaving) in your phone until your service stops working. Then insert your {{displayName}} SIM.'
    },
    getNewNumber: {
        esimTitleMessage: 'Nearly there! Please download your eSIM profile to continue to activation.',
        numTitleMessage: 'This can take up to 4 hours but most of our customers get up and running much sooner. You’ll receive an email when it’s ready to use.',
        numSimHeader: '1. Insert your SIM',
        numSimContent: 'If you haven’t already, insert your {{displayName}} SIM now.'
    },
    directoryListing: {
        header: 'Directory listing',
        contactUsMessage: 'How to change your listing',
        // eslint-disable-next-line max-len
        content:
            'Your name, address and phone number will not be listed in the directory assistance or the printed and online public directories. You can change this in'
    },
    esimProfileError: {
        esimProfileErrortitle: 'Nearly there!',
        esimProfileErrorTitleMessage: 'Please wait for our email confirming that your eSIM is active and then follow the steps below to download your eSIM profile in the My Telstra app.',
        step1: 'Step 1',
        step1Message: 'After receiving Telstra welcome email, open the My Telstra app',
        step2: 'Step 2',
        step2Message: 'On the services tab, select your new eSIM service?',
        step3: 'Step 3',
        step3Message: 'On the service summary page, select Transfer or restore eSIM.',
        step4: 'Step 4',
        step4Message: 'Select Transfer service to this device and ensure you are connected to Wi-Fi.',
        step5: 'Step 5',
        step5Message: 'Follow the steps to verify your account.',
        step6: 'Step 6',
        step6Message: 'Install and download your eSIM profile.'
    }
};

export default ACTIVATION_GUIDE_LOCALE;
