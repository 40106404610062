import { ActionButton, InlineLink, TextStyle } from "@able/react";
import React, { Fragment, useEffect } from "react";
import { applyBrandDataInTemplate, focusOnHeading, getBrandDetails, getCurrentRouteName, nextPage, scrollToBody } from "../../adapters/utils";
import { Spinner } from "../../components/Spinner";
import { EXCEEDED_ATEMPTS, IDVD_FATAL_MANUAL, IDVD_FATAL_CONNECT_ID } from "../../adapters/errorCode";
import { BRANDS, dataLayerEventActions, userProfileInfoMeta } from "../../adapters/constants";
import FullPageError from "../../components/FullPageError/FullPageError";
import { redirectToMyTelstra } from "../../adapters/esim-utils";
import ReadOnlyField from "../your-contact-details/components/readonlyField/readOnlyField";
import FatalErrorIcon from '../../assets/img/svg/error-104.svg';
import ErrorIcon from '../../assets/img/svg/warning-104.svg';
import Parser from 'html-react-parser';
import { addDataLayerEventInfo, addDataLayerUser } from "../../adapters/analytics-utils";
import { ANALYTICS_DOCTYPE_MAP } from "./helper";

export default function View({
    actions,
    appData,
    history,
    idVerificationInProgress,
    magicUrlApiMeta,
    idvDataApiMeta,
    userIdVerified,
    isSoftStopError,
    digitalDataSnapshot,
    loggedInCustomerData,
    customerDataFetchSuccess
}) {
    useEffect(() => {
        const stepperData = {
            totalSteps: appData.uiState.isEsimActivation ? 7 : 6,
            currentStep: 3,
            isLastStepCompleted: false
        };
        actions.updateStepperCustomData(stepperData);

        if (idVerificationInProgress) {
            if (performance.getEntriesByType("navigation")?.[0]?.type === 'back_forward') {
                actions.resetAndReload();
            } else {
                continuePPAFlow();
            }
        } else if (!userIdVerified) {
            startVerification();
        }

        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        scrollToBody('body');

        if (userIdVerified) {
            focusOnHeading();
        }

    }, []);

    const brandDetails = getBrandDetails();

    const onClickContinue = () => {
        nextPage(actions.updateStepperCustomData);
    }

    const continuePPAFlow = () => {
        const userData = digitalDataSnapshot?.user;
        actions.endIDVFlow();
        history.push('/welcome');
        history.push('/your-id-details');
        actions.getValidatedIdvData(data => {
            if(userData) {
                window.digitalData.user = userData;
            }
            const docTypeValue = ANALYTICS_DOCTYPE_MAP[data.documentType];
            addDataLayerEventInfo(dataLayerEventActions.ID_VERIFIED, docTypeValue);
            addDataLayerUser({ type: userProfileInfoMeta.IDENTITY_TYPE, value: docTypeValue });
            addDataLayerUser({ type: userProfileInfoMeta.COUNTRY_OF_IDENTITY, value: data.docStateOrCountry });
            nextPage(actions.updateStepperCustomData);
        });
    }

    const startVerification = () => {
        actions.fetchMagicUrl();
    }

    const renderError = () => {
        let errorProps = {
            errorText: 'Something went wrong',
            errorMessage: 'We’re unable to process the request at this time. Please try again later.'
        };
        if (idvDataApiMeta.errorCode === EXCEEDED_ATEMPTS) {
            errorProps = {
                errorText: 'Your ID check failed',
                errorMessage: 'You’ve reached the maximum number of attempts.',
                errorMessage1: 'To complete your ID check and activation, we’ll need to assist you' + `${brandDetails.brandName == BRANDS.JBHiFi.name ? ' at your local JB Hi-Fi store.' : ' in store.'}`,
                errorMessage2:
                `${brandDetails.brandName == BRANDS.JBHiFi.name ? "" : "<p>To secure a specific time with one of our team members, you can book an appointment at your nearest store at <a href='https://www.telstra.com.au/telstra-store/appointments' style='text-decoration:none' target='_blank'>Telstra.com.</a></p>"}`+
                '<p>Please remember to bring your ID.</p>'
            };
        }
        else if (idvDataApiMeta.errorCode === IDVD_FATAL_MANUAL) {
            errorProps = {
                errorText: 'Your ID check failed',
                errorMessage: Parser('To complete your ID check and activation, we’ll need to assist you' + `${brandDetails.brandName == BRANDS.JBHiFi.name ? ' at your local <p>JB Hi-Fi store.</p>' : ' in store.'}`),
                errorMessage2:
                `${brandDetails.brandName == BRANDS.JBHiFi.name ? "" : "<p>To secure a specific time with one of our team members, you can book an appointment at your nearest store at <a href='https://www.telstra.com.au/telstra-store/appointments' style='text-decoration:none' target='_blank'>Telstra.com.</a></p>"}`+
                '<p>Please remember to bring your ID.</p>',
                errorImg: FatalErrorIcon
            };
            addDataLayerEventInfo('error', 'Hardstop', 'PPA Your ID check failed', errorProps.errorMessage);
        }
        else if (idvDataApiMeta.errorCode === IDVD_FATAL_CONNECT_ID) {
            errorProps = {
                errorText: 'Your ID check failed',
                errorMessage: 'We couldn’t verify your identity with ConnectID.  You’ll need to manually verify your ID document.',
                errorMessage2: 'Alternatively, visit a ' + `${brandDetails.brandName == BRANDS.JBHiFi.name ? 'local JB Hi-Fi store.' : 'Telstra store.'}` +
                    `${brandDetails.brandName == BRANDS.JBHiFi.name ? "" : "<p>To secure a specific time with one of our team members, you can book an appointment at your nearest store at <a href='https://www.telstra.com.au/telstra-store/appointments' style='text-decoration:none' target='_blank'>Telstra.com.</a></p>"}`+
                    '<p>Please remember to bring your ID.</p>',
                errorImg: ErrorIcon
            };
            addDataLayerEventInfo('error', 'Softstop', 'PPA Your ID check failed', errorProps.errorMessage);
        }
        return appData.uiState?.isEsimActivation && !isSoftStopError ? redirectToMyTelstra() : (
            <div className="activate-class">
                <FullPageError {...errorProps} isSoftStopError={isSoftStopError} />
                {idvDataApiMeta.errorCode === IDVD_FATAL_CONNECT_ID && (
                    <div className="activate-button-div">
                        <ActionButton className="mt-2 w-100" element="button" variant="HighEmphasis" label="Verify your ID again" onClick={startVerification} />
                    </div>
                )}
            </div>
        )
    }

    const renderPersonalDetails = () => {
        const { uiState: { loggedIn = false } } = appData;
        const {
            firstName = '',
            middleName = '',
            lastName = '',
            dob = ''
        } = loggedIn && customerDataFetchSuccess && !userIdVerified
            ? {
                  firstName: loggedInCustomerData?.givenNames ?? '',
                  middleName: loggedInCustomerData?.middleNames ?? '',
                  lastName: loggedInCustomerData?.familyNames ?? '',
                  dob: loggedInCustomerData?.dob ?? ''
              }
            : {
                  firstName: idvDataApiMeta?.data?.firstName ?? '',
                  middleName: idvDataApiMeta?.data?.middleName ?? '',
                  lastName: idvDataApiMeta?.data?.lastName ?? '',
                  dob: idvDataApiMeta?.data?.dob ?? ''
              };
        return (
            <dl>
                <ReadOnlyField label="Given name/s" value={firstName} valueClassName="mt-2" labelElement="dt" valueElement="dd" />
                <ReadOnlyField label="Middle name/s" value={middleName} valueClassName={middleName ? 'mt-2' : 'mt-5'} labelElement="dt" valueElement="dd" />
                <ReadOnlyField label="Family name" value={lastName} valueClassName="mt-2" labelElement="dt" valueElement="dd" />
                <ReadOnlyField label="Date of birth" value={dob} valueClassName="mt-2" labelElement="dt" valueElement="dd" />
            </dl>
        );
    }

    const renderSpinner = () =>
        <div className="idv-loader">
            <Spinner />
        </div>;

    return (
        <div className="container ppa-block id-page">
            {(idVerificationInProgress || magicUrlApiMeta.loading || idvDataApiMeta.loading)
                ? renderSpinner()
                : (magicUrlApiMeta.error || idvDataApiMeta.error) && renderError()
            }
            {userIdVerified &&
                <Fragment>
                    <div className="row">
                        <div className="col-lg-5">
                            <TextStyle tabIndex="-1" element="h1" alias="HeadingA" className="id-page-heading mb-4">
                                Your Details
                            </TextStyle>
                            {renderPersonalDetails()}
                            <ActionButton className="mt-2 w-100" element="button" variant="HighEmphasis" label="Continue" onClick={onClickContinue} />
                            <InlineLink variant="Default">
                                <TextStyle alias="FinePrintA" className="mt-4" colour="Subtle">
                                    Information about how we collect, store, use and share your personal information can be found in our{' '}
                                    <a target={'_blank'} href="https://www.telstra.com.au/privacy#info-collect">
                                        Privacy Statement
                                    </a>
                                    .
                                </TextStyle>
                            </InlineLink>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}