const AUTORECHARGE_LOCALE = {
    title: 'Set up auto recharge',
    para: 'We’ll automatically recharge your service before expiry. ',
    specialOffer: {
        label: 'Special Offer',
        para: 'recharges when you set up auto recharge.',
        discountDetails: 'This discount will be applied on your next recharge. You need to set up auto recharge before ',
        bonusDetails: 'This offer will be applied on your next recharge. You need to set up auto recharge before '
    },
    checkbox: {
        label: 'Enable auto recharge',
        details: 'Your payment details will be saved and automatically charged ',
        alert: {
            description: 'By clicking \'Activate SIM\', you acknowledge the auto recharge price will increase, and some plan inclusions are changing from 22 October 2024',
            actionText: 'View our recharge changes',
            titleText: 'Auto recharge price increase'
        }
    },
    btnText: 'Activate SIM',
    note: 'The information you provide us will be treated in accordance with this Privacy Statement.',
    confirmModel: {
        title: 'Confirm auto recharge',
        para: 'Your chosen payment method will be saved and automatically charged ',
        btnText1: 'Confirm and activate',
        btnText2: 'Skip and activate'
    },
    LearnMoreModal: {
        title: 'What is auto recharge?',
        learnMoreText: 'Learn more',
        para1: 'Auto recharge is the automatic recharge of your mobile service.',
        para2: 'With auto recharge, your payment method details will be saved. We’ll then charge this card each time your recharge expires.',
        para3: 'You can manage your payment preferences in My Telstra.',
        btnText: 'Got it'
    },
    AutoRechargeEnabledCard: {
        title: 'Auto recharge enabled',
        autoRechargeContent: 'Your chosen payment method will be charged ',
        authPara1: 'To manage your payment preferences, use your ',
        unauthpara1: 'To manage your payment preferences online, complete the verification process below.'
    }
};

export default AUTORECHARGE_LOCALE;
