import React, { useEffect, useRef } from 'react';
import { IconButton, TextStyle } from "@able/react";
import Modal from 'react-modal';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import PortingLock from '../../../../assets/img/svg/Security.svg';
import { MAX_ATTEMPTS_SEND_OTC, SERVER_ERROR, SEND_OTC_ERROR, UNKNOWN_ERROR } from '../../../../adapters/errorCode';
import { FullModalError } from '../fullModalError/fullModalError';
import { focusOnModal, getAbleSpriteSheet } from '../../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();
const OtcInfo = ({ newEmail, onCloseModal, onContinue: onSendCode, isProcessingOtp, errorCode, resetOTCState }) => {

    const modal1 = useRef(null);

    useEffect(() => {
        modal1 && modal1.current && focusOnModal(modal1, 0);
    }, []);

    const renderModalContent = () => {
        return (
            <div className="modal-content ppv-modal-content skip-recharge-content update-modal-content">
                <div className="modal-header-no-border ppv-header update-contact-modal-header">
                    <img className="porting-lock-image mb-4" src={PortingLock} aria-hidden="true" alt="" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={onCloseModal} />
                </div>
                <div tabIndex="-1" className="skip-recharge-content-container modal-heading" id="verify-access-heading">
                    <TextStyle element="h2" tabIndex="-1" alias="HeadingB">
                        Verify access to your contact details
                    </TextStyle>
                    <TextStyle className="send-code-info" alias="Base">
                        {`We’ll send you a one-time code to ${newEmail} to verify you have access to it.`}
                    </TextStyle>
                </div>
                <SpinningButton
                    id="verifyButton"
                    onSubmit={onSendCode}
                    stylingClass="send-code"
                    isDisabled={isProcessingOtp}
                    isLoading={isProcessingOtp}
                    buttonText="Send code"
                />
            </div>
        );
    };

    const closeModal = () => {
        if (errorCode) {
            resetOTCState();
        }
        onCloseModal();
    }

    let errorProps;
    if (errorCode) {
        if (errorCode === MAX_ATTEMPTS_SEND_OTC) {
            errorProps = {
                errorHeading: 'Unable to process request',
                errorText: "You've reached the maximum attempts for requesting your one-time code. You'll need to wait at least 20 minutes before trying again."
            };
        } else if (
            errorCode === SEND_OTC_ERROR ||
            errorCode === UNKNOWN_ERROR ||
            errorCode === SERVER_ERROR
        ) {
            errorProps = {
                errorHeading: 'Something went wrong',
                errorText: "We're unable to process your request. Please try again later.",
                errorTextLink: 'Try again'
            };
        }
    }

    return (
        <Modal
            parentSelector={() => document.querySelector('#modal-ppv')}
            shouldCloseOnOverlayClick={false}
            isOpen={true}
            className="modal-dialog modal-lg ppv-modal"
            contentLabel="Verify access to your contact details"
            aria={{ labelledby: 'verify-access-heading', modal: 'true', role: 'dialog' }}
            onRequestClose={closeModal}
            shouldCloseOnEsc={true}
            ref={modal1}
        >
            {errorProps ?
                <FullModalError onCloseModal={closeModal} errorContent={errorProps} />
                : renderModalContent()}
        </Modal>
    );
};

export default OtcInfo;
