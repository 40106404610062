import React, { useRef } from 'react';
import GLOBAL_FOOTER from './GlobalFooterConstants';
import './globalFooter.css';
import { getUiState } from '../../adapters/store-utils';
import { TextStyle } from '@able/react';

const GlobalFooter = () => {
    const { isEsimActivation = false } = getUiState() || {};
    const homeLink = useRef(null);
    const customerAdviceLink = useRef(null);
    const criticalInfoLink = useRef(null);
    const helpAndSupportLink = useRef(null);
    const termsOfUseLink = useRef(null);
    const privacyLink = useRef(null);

    const onMouseEnter = (elem) => {
        elem.current.classList.add('footer-anchor-hover');
    };

    const onMouseLeave = (elem) => {
        elem.current.classList.remove('footer-anchor-hover');
    };

    const onMouseUp = (elem) => {
        elem.current.blur();
        elem.current.classList.remove('footer-anchor-hover');
    };

    return (
        <div className="telstra-footer">
            <TextStyle alias="FinePrintA" className="footer-container">
                <nav className="footer-first-three">
                    {!isEsimActivation && (
                        <a
                            ref={homeLink}
                            onMouseUp={() => onMouseUp(homeLink)}
                            onMouseEnter={() => onMouseEnter(homeLink)}
                            onMouseLeave={() => onMouseLeave(homeLink)}
                            onTouchEnd={() => onMouseUp(homeLink)}
                            onTouchStart={() => onMouseEnter(homeLink)}
                            className="foot-height"
                            href={GLOBAL_FOOTER.HOME}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Home
                        </a>
                    )}
                    <a
                        ref={helpAndSupportLink}
                        onMouseUp={() => onMouseUp(helpAndSupportLink)}
                        onMouseEnter={() => onMouseEnter(helpAndSupportLink)}
                        onMouseLeave={() => onMouseLeave(helpAndSupportLink)}
                        onTouchEnd={() => onMouseUp(helpAndSupportLink)}
                        onTouchStart={() => onMouseEnter(helpAndSupportLink)}
                        className="foot-height"
                        href={GLOBAL_FOOTER.HELP_AND_SUPPORT}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Help & Support
                    </a>
                    <a
                        ref={customerAdviceLink}
                        onMouseUp={() => onMouseUp(customerAdviceLink)}
                        onMouseEnter={() => onMouseEnter(customerAdviceLink)}
                        onMouseLeave={() => onMouseLeave(customerAdviceLink)}
                        onTouchEnd={() => onMouseUp(customerAdviceLink)}
                        onTouchStart={() => onMouseEnter(customerAdviceLink)}
                        className="foot-height"
                        href={GLOBAL_FOOTER.CONSUMER_ADVICE}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Consumer advice
                    </a>
                    <a
                        ref={criticalInfoLink}
                        onMouseUp={() => onMouseUp(criticalInfoLink)}
                        onMouseEnter={() => onMouseEnter(criticalInfoLink)}
                        onMouseLeave={() => onMouseLeave(criticalInfoLink)}
                        onTouchEnd={() => onMouseUp(criticalInfoLink)}
                        onTouchStart={() => onMouseEnter(criticalInfoLink)}
                        className="foot-height"
                        href={GLOBAL_FOOTER.CRITICAL_INFORMATION_SUMMARIES}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Critical information summaries
                    </a>
                </nav>
                <nav className="footer-last-two">
                    <a
                        ref={termsOfUseLink}
                        onMouseUp={() => onMouseUp(termsOfUseLink)}
                        onMouseEnter={() => onMouseEnter(termsOfUseLink)}
                        onMouseLeave={() => onMouseLeave(termsOfUseLink)}
                        onTouchEnd={() => onMouseUp(termsOfUseLink)}
                        onTouchStart={() => onMouseEnter(termsOfUseLink)}
                        className="foot-height"
                        href={GLOBAL_FOOTER.TERMS_OF_USE}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of use
                    </a>
                    <a
                        ref={privacyLink}
                        onMouseUp={() => onMouseUp(privacyLink)}
                        onMouseEnter={() => onMouseEnter(privacyLink)}
                        onMouseLeave={() => onMouseLeave(privacyLink)}
                        onTouchEnd={() => onMouseUp(privacyLink)}
                        onTouchStart={() => onMouseEnter(privacyLink)}
                        className="foot-height"
                        href={GLOBAL_FOOTER.PRIVACY}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy
                    </a>
                </nav>
            </TextStyle>

        </div>
    );
};

export default GlobalFooter;
