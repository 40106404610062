import React, { Fragment } from 'react';
import { ActionButton } from '@able/react';
import { getAbleSpriteSheet } from '../../adapters/utils';
import { Logo, BaseEarth60AndSky60 } from '@able/react';
const ableSpriteSheet = getAbleSpriteSheet();
class SignOut extends React.PureComponent {
    render() {
        const { appData: { uiState: { loggedIn = false, isEsimActivation = false } = {} } = {} } = this.props;
        return (
            <Fragment>
                <div className="telstra-header">
                    <div className="telstra-header-container">
                        <a href="https://www.telstra.com.au/" className="telstra-logo">
                            <Logo className="eg-Logo eg-Logo--Earth60AndSky60" id="t-logo">
                                <BaseEarth60AndSky60 />
                            </Logo>
                        </a>
                        {!isEsimActivation && loggedIn && (
                            <ActionButton
                                developmentUrl={ableSpriteSheet}
                                element="button"
                                variant="LowEmphasis"
                                label="Sign out"
                                icon="SignOut"
                                className="sign-out"
                                onClick={window.TelstraAuth.logout} />
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SignOut;
